<template>
  <div class="moment">
    <div class="moment-banner1">
      <Banner />
    </div>
    <div class="moments" v-if="moments && moments.length > 0">
      <div v-for="(m, index) of moments" :key="index" class="moment-img">
        <div
          class="img-bg"
          :style="{ 'background-image': `url(${m.thumbnail.url})` }"
        ></div>
        <div v-if="m.network" :class="`network-icon ${m.network}`" />
      </div>
    </div>
    <div class="box-btn-view">
      <button class="btn-view">View More Snapshots</button>
    </div>
    <div class="moment-banner2">
      <Banner />
    </div>
  </div>
</template>

<script>
import Repository from "../services/Repository.js";
import Banner from "./Banner.vue";
import iconIg from "@/assets/images/icon-ig.svg";
import img01 from "@/assets/images/img01.svg";
import img02 from "@/assets/images/img02.svg";
import img03 from "@/assets/images/img03.svg";
import img04 from "@/assets/images/img04.svg";
import img05 from "@/assets/images/img05.svg";
import img06 from "@/assets/images/img06.svg";
import img07 from "@/assets/images/img07.svg";
import img08 from "@/assets/images/img08.svg";
import img09 from "@/assets/images/img09.svg";
import img10 from "@/assets/images/img10.svg";

const repo = new Repository();

export default {
  components: {
    Banner,
  },
  props: {
    socialIdentifiers: String,
    limit: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      moments: [],
      iconIg: iconIg,
      img01: img01,
      img02: img02,
      img03: img03,
      img04: img04,
      img05: img05,
      img06: img06,
      img07: img07,
      img08: img08,
      img09: img09,
      img10: img10,
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      if (!this.socialIdentifiers) return;

      const { results } = await repo.getMomentsBySocialIdentifiers(
        this.socialIdentifiers,
        this.limit,
        1
      );
      this.moments = results;
    },
  },
};
</script>

<style lang="scss">
.moment {
  width: 100%;
  .moments {
    width: 100%;
    height: 25vw;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
    margin: 0 -3px;
    order: 1;

    .moment-img {
      .img-bg {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      &:first-child {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
      }

      &:last-child {
        grid-column: 7 / span 2;
        grid-row: 1 / span 2;
      }
    }

    .img-left,
    .img-right {
      width: 25%;
      height: 100%;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
      .ig {
        position: absolute;
        left: 15px;
        bottom: 15px;
      }
    }

    .img-center {
      width: 50%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      .list {
        width: 100%;
        height: 50%;
        display: flex;
        .items {
          width: 25%;
          position: relative;
          padding: 0;
          padding: 1px;
          .img {
            width: 100%;
            height: 100%;
            background-size: cover;
          }
          .ig {
            position: absolute;
            left: 15px;
            bottom: 15px;
          }
        }
      }
    }
  }

  .moment-banner1 {
    display: none;
  }

  .moment-banner2 {
    padding: 55px 0 85px;
    display: block;
  }

  .box-btn-view {
    display: none;
  }

  @media only screen and (max-width: 900px) {
    .moments {
      width: 100%;
      height: 200vw;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      margin: 0 -3px;
      order: 2;

      .moment-img {
        .img-bg {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        &:first-child {
          grid-column: 1 / span 1;
          grid-row: 1 / span 1;
        }

        &:last-child {
          grid-column: 2 / span 1;
          grid-row: 5 / span 1;
        }
      }
    }

    .moment-banner1 {
      padding: 70px 0 30px;
      display: block;
    }

    .moment-banner2 {
      display: none;
    }

    .box-btn-view {
      display: block;
      padding: 10px;
      .btn-view {
        width: 100%;
        height: 100%;
        background-color: #0058ff;
        border: 0;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        padding: 15px;
      }
    }
  }
}
</style>