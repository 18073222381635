<template>
  <div class="affiliations">
    <h1>Affiliations</h1>
    <ul class="list-logo">
      <li>
        <img :src="mekong1" alt="" />
      </li>
      <li>
        <img :src="mekong2" alt="" />
      </li>
    </ul>

    <h1>Dali, Yunnan, China</h1>
    <div class="map">
      <l-map
        v-if="showMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="height: 80%"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
      >
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-marker :lat-lng="withPopup">
          <l-popup>
            <div @click="innerClick">
              I am a popup
              <p v-show="showParagraph">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
                Donec finibus semper metus id malesuada.
              </p>
            </div>
          </l-popup>
        </l-marker>
        <l-marker :lat-lng="withTooltip">
          <l-tooltip :options="{ permanent: true, interactive: true }">
            <div @click="innerClick">
              I am a tooltip
              <p v-show="showParagraph">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
                Donec finibus semper metus id malesuada.
              </p>
            </div>
          </l-tooltip>
        </l-marker>
      </l-map>
      <!-- <img :src="map" alt=""> -->
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import mekong1 from "@/assets/images/mekong1.svg";
import mekong2 from "@/assets/images/mekong2.svg";
import map from "@/assets/images/map.svg";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
  },
  data() {
    return {
      mekong1: mekong1,
      mekong2: mekong2,
      map: map,
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
  },
};
</script>

<style lang="scss">
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
.affiliations {
  margin: 0 auto;
  padding: 60px 0;
  width: 1024px;

  #mapid {
    height: 180px;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .list-logo {
    display: flex;
    margin: 0 -15px 30px;
    li {
      padding: 0 15px;
    }
  }

  .map {
    width: 100%;
    height: 500px;
    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 20px 20px 40px;
    .map {
      height: 250px;
    }

    h1 {
      font-size: 15px;
      padding-left: 50px;
    }

    .list-logo {
      width: 100%;
      justify-content: center;
      li {
        img {
          width: 55px;
        }
      }
    }
  }
}
</style>