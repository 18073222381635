<template>
  <div class="about">
    <div class="description">
      <div class="map">
        <l-map
          v-if="showMap"
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          style="height: 80%"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
        >
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-marker :lat-lng="withPopup">
            <l-popup>
              <div @click="innerClick">
                I am a popup
                <p v-show="showParagraph">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque sed pretium nisl, ut sagittis sapien. Sed vel
                  sollicitudin nisi. Donec finibus semper metus id malesuada.
                </p>
              </div>
            </l-popup>
          </l-marker>
          <l-marker :lat-lng="withTooltip">
            <l-tooltip :options="{ permanent: true, interactive: true }">
              <div @click="innerClick">
                I am a tooltip
                <p v-show="showParagraph">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque sed pretium nisl, ut sagittis sapien. Sed vel
                  sollicitudin nisi. Donec finibus semper metus id malesuada.
                </p>
              </div>
            </l-tooltip>
          </l-marker>
        </l-map>
        <!-- <img :src="mapAbout" style="width: 100%" /> -->
      </div>
      <div class="detail">
        <div class="">
          <h1>About</h1>
          <div class="text">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut
              ligula imperdiet, dapibus justo ac, dapibus sem. Class aptent
              taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Aliquam consectetur ornare dui nec hendrerit.
              Phasellus egestas eros et sagittis consectetur. Orci varius
              natoque penatibus et magnis dis parturient montes, nascetur
              ridiculus mus. Fusce sodales enim dolor, in commodo nunc egestas
              sed. Suspendisse elementum porttitor consequat.
            </p>
            <p>
              Nullam a condimentum eros. Sed malesuada accumsan ligula, in
              egestas magna gravida id. Duis lobortis, purus sed luctus
              condimentum, dolor lacus volutpat dolor, ac molestie metus mauris
              quis tortor. Sed quis feugiat augue. Donec a odio nec neque mollis
              viverra non suscipit odio. Sed nunc arcu, fringilla sit amet
              lectus id, pharetra consectetur ex. Etiam justo lorem, imper diet
              sit amet arcu vel, dapibus imperdiet diam. Mauris gravida justo ut
              ven enatis vehicula. Vivamus ut est sapien. Donec vel fermentum
              massa.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <ul>
        <li v-for="(item, index) in contact" :key="index">
          <div class="icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="text" v-html="item.text"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import mapAbout from "@/assets/images/map-about.svg";
import iconContact01 from "@/assets/images/icon-contact-01.svg";
import iconContact02 from "@/assets/images/icon-contact-02.svg";
import iconContact03 from "@/assets/images/icon-contact-03.svg";
import iconContact04 from "@/assets/images/icon-contact-04.svg";
import iconContact05 from "@/assets/images/icon-contact-05.svg";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
  },
  data() {
    return {
      mapAbout: mapAbout,
      contact: [
        {
          icon: iconContact01,
          text: `#5 Cheng Bei village <br />
            Xizhou Town, Dali City, <br />
            Yunnan Province, China`,
        },
        {
          icon: iconContact02,
          text: `(86)872-2452988`,
        },
        {
          icon: iconContact03,
          text: `reservations@linden-centre.com`,
        },
        {
          icon: iconContact04,
          text: `www.linden-centre.com`,
        },
        {
          icon: iconContact05,
          text: `Operated.planning.rated`,
        },
      ],
      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
  },
};
</script>

<style lang="scss">
@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
.about {
  margin: 45px 0 100px;

  .description {
    display: flex;
    width: 100%;
    
    .map {
      width: 35%;
      height: 550px;
    }

    .detail {
      padding-left: 90px;
      padding-right: 250px;
      width: 65%;

      p {
        margin: 20px 0;
      }
    }
  }

  .contact {
    width: 100%;

    ul {
      display: flex;
      justify-content: center;
      li {
        display: flex;
        padding: 20px;
        white-space: nowrap;

        .icon {
          padding-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .description {
      flex-wrap: wrap;
      .map {
        width: 100%;
        height: 450px;
      }

      .detail {
        width: 100%;
        padding: 0 20px;

        h1 {
          font-size: 15px;
          font-weight: bold;
        }

        p {
          font-size: 10px;
        }
      }
    }

    .contact {
      margin-top: 0;
      ul {
        flex-wrap: wrap;
        justify-content: start;

        li {
          width: 100%;
          padding: 5px 20px;

          .icon {
            img {
              width: 20px;
            }
          }

          .text {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>