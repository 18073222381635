<template>
  <div class="ew-page" v-if="experience">
    <!-- <Header :logo="logo" :homeUrl="homeUrl" /> -->
    <TopSection
      :title="experience.title"
      :image="
        experience.sliderImageUrls && experience.sliderImageUrls.length > 0
          ? experience.sliderImageUrls[0]
          : experience.thumbnailUrl
      "
      :address="experience.address"
    />
    <ListMenu />
    <Moment :socialIdentifiers="experience.socialIdentifiers" />
    <Website />
    <Affiliations />
    <Nearby
      :nearbyExperiences="experience.nearbyExperiences"
      :nearbyHotels="experience.nearbyHotels"
    />
    <Offers />
    <About />
    <FeaturedOn />
    <Footer />
  </div>
</template>

<script>
import Repository from "../services/Repository";
import Header from "./Header.vue";
import TopSection from "./TopSection.vue";
import ListMenu from "./ListMenu.vue";
import Moment from "./Moment.vue";
import Website from "./Website.vue";
import Affiliations from "./Affiliations.vue";
import Nearby from "./Nearby.vue";
import Offers from "./Offers.vue";
import About from "./About.vue";
import FeaturedOn from "./FeaturedOn.vue";
import Footer from "./Footer.vue";

const repo = new Repository();

export default {
  components: {
    Header,
    TopSection,
    ListMenu,
    Moment,
    Website,
    Affiliations,
    Nearby,
    Offers,
    About,
    FeaturedOn,
    Footer
  },
  props: {
    id: String,
    name: String,
    logo: String,
    homeUrl: String,
    experiencePath: String,
  },
  watch: {
    name() {
      this.load();
    },
  },
  data() {
    return {
      experience: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.id && !this.name) {
        this.experience = null;
      } else {
        let experience = await repo.getExperienceByName(this.id, this.name);
        this.experience = this.prepare(experience);
      }
    },
    prepare(experience) {
      experience.experienceUrl = this.getExperienceUrl(experience);
      experience.mainImageUrl = this.getMainImageUrl(experience);
      if (experience.nearbyHotels) {
        experience.nearbyHotels.forEach((it) => {
          it.experienceUrl = this.getExperienceUrl(it);
          it.mainImageUrl = this.getMainImageUrl(it);
          it.distanceText = this.getDistanceText(it.distance);
        });
      }
      if (experience.nearbyExperiences) {
        experience.nearbyExperiences.forEach((it) => {
          it.experienceUrl = this.getExperienceUrl(it);
          it.mainImageUrl = this.getMainImageUrl(it);
          it.distanceText = this.getDistanceText(it.distance);
        });
      }
      return experience;
    },
    getExperienceUrl(exp) {
      if (!this.experiencePath) {
        return `/${exp.name || exp.id}`;
      } else {
        return this.experiencePath + exp.name;
      }
    },
    getDistanceText(distance) {
      const kilometers = distance / 1000;
      let text = "";
      if (kilometers >= 1) {
        text = kilometers.toFixed(1) + " km away";
      } else {
        text = distance.toFixed(0) + " m away";
      }
      return text;
    },
    getMainImageUrl(exp) {
      return exp.sliderImageUrls && exp.sliderImageUrls.length > 0
        ? exp.sliderImageUrls[0]
        : exp.thumbnailUrl;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="date" i] {
  align-items: none;
  display: block;
  font-family: monospace;
  padding-inline-start: 0;
  cursor: default;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  border: 0;
  font-size: 100%;
}

h1 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
</style>
