<template>
  <div class="featured-on">
    <h1>Featured on</h1>
    <div class="list-featured">
      <ul>
        <li>
          <img :src="imgFeatuerd" alt="" />
        </li>
        <li>
          <img :src="imgFeatuerd" alt="" />
        </li>
      </ul>
    </div>
    <Banner />
  </div>
</template>
<script>
import imgFeatuerd from "@/assets/images/img-featured.svg";
import Banner from "./Banner.vue";
export default {
  components: {
    Banner,
  },
  data() {
    return {
      imgFeatuerd: imgFeatuerd,
    };
  },
};
</script>

<style lang="scss">
.featured-on {
  margin: 0 auto;
  width: 1024px;

  .list-featured {
    ul {
      display: flex;
      margin: 0 -15px;
      li {
        padding: 15px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 0 20px;
    width: 100%;
  }
}
</style>