<template>
  <div class="offers">
    <div class="offer">
      <h1>Offers</h1>
      <div class="slideshow-container" v-if="itemsSlide">
        <div class="mySlides fade">
          <img :src="isSlide.slideImage" style="width: 100%" />
          <div class="text">{{ isSlide.title }}</div>
        </div>
        <div class="btn-change-slide">
          <a class="prev" @click="plusSlides(-1)">
            <img :src="prev" alt="" />
          </a>
          <a class="next" @click="plusSlides(1)">
            <img :src="next" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div class="ways-to-experience">
      <h1>Ways to Experience</h1>
      <div class="row">
        <div class="list-3">
          <div class="box">
            <div class="image">
              <div class="text-up">Short writeup</div>
            </div>
            <div class="text">Bike Tour City</div>
          </div>
        </div>
        <div class="list-3">
          <div class="box">
            <div class="image">
              <div class="text-up">Short writeup</div>
            </div>
            <div class="text">Bike Tour City</div>
          </div>
        </div>
        <div class="list-3">
          <div class="box">
            <div class="image">
              <div class="text-up">Short writeup</div>
            </div>
            <div class="text">Bike Tour City</div>
          </div>
        </div>
      </div>
    </div>
    <div class="storie">
      <h1>Stories</h1>
      <div class="row">
        <div class="list-2" v-for="(item, index) in stories" :key="index">
          <div class="box">
            <div class="detail-storie">
              <div
                class="bg-image"
                :style="`background-image: url(${item.image})`"
              ></div>

              <div class="bg-text">
                <div class="text">
                  <h2>{{ item.title }}</h2>
                  <p>{{ item.by }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="button">
          <button class="btn-storie">See all</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slide from "@/assets/images/slide.svg";
import left from "@/assets/images/left.svg";
import right from "@/assets/images/right.svg";
import storie01 from "@/assets/images/storie01.svg";
import storie02 from "@/assets/images/storie02.svg";
export default {
  data() {
    return {
      slideIndex: 1,
      isSlide: {},
      itemsSlide: [
        {
          slideImage: slide,
          title: "slide-1",
        },
        {
          slideImage: slide,
          title: "slide-2",
        },
        {
          slideImage: slide,
          title: "slide-3",
        },
      ],
      prev: left,
      next: right,
      stories: [
        {
          image: storie01,
          title: "The Linden Story",
          by: "by Duen Punyashthiti",
        },
        {
          image: storie02,
          title: "The Linden Story",
          by: "by Duen Punyashthiti",
        },
      ],
    };
  },
  mounted() {
    this.showSlides(this.slideIndex);
  },
  methods: {
    isLoad() {
      alert("Hellowordl");
    },
    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide(n) {
      this.showSlides((this.slideIndex = n));
    },
    showSlides(n) {
      if (n > this.itemsSlide.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = this.itemsSlide.length;
      }
      this.isSlide = this.itemsSlide[this.slideIndex - 1];

      // for (i = 0; i < slides.length; i++) {
      //   slides[i].style.display = "none";
      // }
      // for (i = 0; i < dots.length; i++) {
      //   dots[i].className = dots[i].className.replace(" active", "");
      // }
      // slides[this.slideIndex - 1].style.display = "block";
      // dots[this.slideIndex - 1].className += " active";
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
.offers {
  margin: 60px auto;
  width: 100%;
  max-width: 1024px;
  .slideshow-container {
    .mySlides {
      position: relative;

      .text {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .btn-change-slide {
      text-align: center;

      .prev,
      .next {
        padding: 5px;
        cursor: pointer;
      }
    }
  }

  .storie,
  .ways-to-experience,
  .offer {
    margin: 45px 0;
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    .list-3 {
      width: 33.33%;
      .box {
        padding: 15px;
        .image {
          width: 100%;
          height: 220px;
          background-color: #bebebe;
          position: relative;
          overflow: hidden;
          .text-up {
            background-color: #fff;
            position: absolute;
            transition: all 0.6s;
            width: 100%;
            height: 0;
            bottom: 0;
            left: 0;
          }
        }

        .image:hover {
          .text-up {
            height: 95%;
          }
        }

        .text {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .list-2 {
      width: 50%;
      .box {
        padding: 15px;
        .detail-storie {
          height: 210px;
          position: relative;
          .bg-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            /* Add the blur effect */
            filter: blur(3px);
            -webkit-filter: blur(3px);
            /* Full height */
            height: 100%;
            /* Center and scale the image nicely */
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }

          .bg-text {
            .text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            background-color: rgb(0, 0, 0); /* Fallback color */
            background-color: rgba(
              0,
              0,
              0,
              0.1
            ); /* Black w/opacity/see-through */
            color: white;
            font-weight: bold;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            padding: 20px;
            width: 100%;
            height: 100%;
            text-align: center;
          }
        }
      }
    }

    .button {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      .btn-storie {
        cursor: pointer;
        background-color: #fff;
        border: 2px #000000 solid;
        border-radius: 40px;
        padding: 10px 20px;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .ways-to-experience {
      display: none;
    }
    .storie {
      padding: 0 20px;

      .row {
        .list-2 {
          width: 100%;
          .box {
            padding: 15px;
            .detail-storie {
              height: 128px;

              .text {
                h2 {
                  font-size: 18px;
                  font-weight: bold;
                }

                p {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>