<template>
  <div class="book-now">
    <div class="detail">
      <div class="name">
        <ul class="list-name">
          <li>{{ title }}</li>
          <li>{{ address }}</li>
        </ul>
      </div>
      <div class="icons"></div>
    </div>

    <form>
      <div class="custom-book-now">
        <div class="form">
          <div class="form-input order-date">
            <div class="col-6">
              <div>
                <input type="date" class="input" />
              </div>
            </div>
            <div class="col-6">
              <div>
                <input type="date" class="input" />
              </div>
            </div>
          </div>
          <div class="form-input order-select">
            <div class="col-4">
              <select class="input">
                <option value="">1 Room</option>
              </select>
            </div>
            <div class="col-4">
              <select class="input">
                <option value="">2 adults</option>
              </select>
            </div>
            <div class="col-4">
              <select class="input">
                <option value="">No children</option>
              </select>
            </div>
          </div>
        </div>
        <div class="button">
          <button type="button" class="btn-submit-book">BOOK NOW</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    address: String,
  },
};
</script>

<style lang="scss">
.book-now {
  width: 660px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  .detail {
    margin-bottom: 20px;
    .name {
      .list-name {
        color: #fff;
        line-height: 30px;
        :nth-child(1) {
          font-size: 35px;
          font-weight: bold;
        }
        :nth-child(2) {
          font-size: 21px;
        }
      }
    }
  }

  form {
    .custom-book-now {
      display: flex;
      .form {
        background-color: rgba(112, 112, 112, 0.5);
        width: 73%;
        padding: 20px 10px 5px;
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-wrap: wrap;
        .form-input {
          display: -webkit-flex; /* Safari */
          display: flex;
          width: 100%;
          padding-bottom: 15px;
          &.order-date {
            order: 1;
          }

          &.order-select {
            order: 2;
          }
        }
      }
      .button {
        width: 27%;
        .btn-submit-book {
          width: 100%;
          height: 100%;
          background-color: #0058ff;
          border: 0;
          border-top-right-radius: 5px;
          font-size: 19px;
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
}

.col-6 {
  width: 50%;
  padding: 0 5px;
}

.col-4 {
  width: 33.33%;
  padding: 0 5px;
}

.input {
  width: 100%;
  max-width: 100%;
  height: 45px;
  border-radius: 2px;
  border: 1px #ddd solid;
}

@media only screen and (max-width: 900px) {
  .book-now {
    width: 90%;
    bottom: -40px;
    .detail {
      margin-bottom: 10px;
      .name {
        .list-name {
          line-height: 20px;
          :nth-child(1) {
            font-size: 22px;
          }
          :nth-child(2) {
            font-size: 13px;
          }
        }
      }
    }

    form {
      .custom-book-now {
        display: flex;
        flex-wrap: wrap;
        background-color: #dedede;
        .form {
          background-color: transparent;
          width: 100%;
          padding: 5px 10px 2px;
          .form-input {
            display: flex;
            padding-bottom: 3px;
            &.order-date {
              order: 2;
            }

            &.order-select {
              order: 1;
            }
          }
        }
        .button {
          width: 100%;
          text-align: center;
          padding: 5px 10px 8px;
          .btn-submit-book {
            width: 100%;
            height: 35px;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .col-6 {
    padding: 0 2px;
  }

  .col-4 {
    padding: 0 2px;
  }

  .input {
    width: 100%;
    max-width: 100%;
    height: 30px;
    border-radius: 0;
    border: 1px #ddd solid;
  }
}
</style>