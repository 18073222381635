<template>
  <div class="ew-page-top-section">
    <div
      class="main-image-bg"
      :style="{ 'background-image': `url(${image})` }"
      v-if="image"
    >
      <BookNow :title="title" :address="address" />
    </div>
  </div>
</template>

<script>
import BookNow from "./BookNow.vue";

export default {
  components: {
    BookNow,
  },
  props: {
    title: String,
    address: String,
    image: String,
    country: String,
    region: String,
    city: String,
  },
};
</script>

<style lang="scss">
.ew-page-top-section {
  width: 100%;
  min-height: 400px;

  .main-image-bg {
    background-size: cover;
    width: 100%;
    height: 919px;
    position: relative;
  }
}

@media only screen and (max-width: 900px) {
  .ew-page-top-section {
    width: 100%;
    min-height: 400px;

    .main-image-bg {
      background-size: cover;
      width: 100%;
      height: 404px;
      position: relative;
    }
  }
}
</style>