<template>
  <div class="container-nearby">
    <div class="nearby">
      <h1>Nearby Hotels</h1>
      <ul class="list-nearby">
        <li v-for="(exp, index) of nearbyHotels" :key="index">
          <div class="box">
            <div class="description">
              <a :href="exp.experienceUrl" target="_blank">
                <div
                  class="image"
                  :style="{
                    'background-image': exp.mainImageUrl
                      ? `url(${exp.mainImageUrl})`
                      : null,
                  }"
                >
                  <span>{{ exp.distanceText }}</span>
                </div>
              </a>
              <div class="detail">
                <button class="btn-booknow">BookNow</button>
                <p class="title">{{ exp.title }}</p>
                <div class="like">
                  <span>210</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="slider" style="width: 100%">
        <splide :options="options" style="width: 100%">
          <splide-slide v-for="(exp, index) of nearbyHotels" :key="index">
            <div class="box">
              <div class="description">
                <a :href="exp.experienceUrl" target="_blank">
                  <div
                    class="image"
                    :style="{
                      'background-image': exp.mainImageUrl
                        ? `url(${exp.mainImageUrl})`
                        : null,
                    }"
                  >
                    <span>{{ exp.distanceText }}</span>
                  </div>
                </a>
                <div class="detail">
                  <button class="btn-booknow">BookNow</button>
                  <p class="title">{{ exp.title }}</p>
                  <div class="like">
                    <span>210</span>
                  </div>
                </div>
              </div>
            </div>
          </splide-slide>
        </splide>
      </div>
      <div style="width: 100%">
        <Banner />
      </div>
      <h1>Nearby Experiences</h1>
      <ul class="list-nearby">
        <li v-for="(exp, index) of nearbyExperiences" :key="index">
          <div class="box">
            <div class="description">
              <a :href="exp.experienceUrl" target="_blank">
                <div
                  class="image"
                  :style="{
                    'background-image': exp.mainImageUrl
                      ? `url(${exp.mainImageUrl})`
                      : null,
                  }"
                >
                  <span>{{ exp.distanceText }}</span>
                </div>
              </a>
              <div class="detail">
                <button class="btn-booknow">BookNow</button>
                <p class="title">{{ exp.title }}</p>
                <div class="like">
                  <span>210</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="slider" style="width: 100%">
        <splide :options="options" style="width: 100%">
          <splide-slide v-for="(exp, index) of nearbyExperiences" :key="index">
            <div class="box">
              <div class="description">
                <a :href="exp.experienceUrl" target="_blank">
                  <div
                    class="image"
                    :style="{
                      'background-image': exp.mainImageUrl
                        ? `url(${exp.mainImageUrl})`
                        : null,
                    }"
                  >
                    <span>{{ exp.distanceText }}</span>
                  </div>
                </a>
                <div class="detail">
                  <button class="btn-booknow">BookNow</button>
                  <p class="title">{{ exp.title }}</p>
                  <div class="like">
                    <span>210</span>
                  </div>
                </div>
              </div>
            </div>
          </splide-slide>
        </splide>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  components: {
    Banner,
    Splide,
    SplideSlide,
  },
  props: {
    nearbyHotels: {
      type: Array,
      default() {
        return [];
      },
    },
    nearbyExperiences: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      options: {
        type: "loop",
        perPage: 1,
        gap: "1rem",
        pagination: false,
        padding: {
          left: "3rem",
          right: "3rem",
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import url("../assets/splide.css");
.test {
  display: flex;
  justify-content: center;
}

.container-nearby {
  padding: 60px;
  background-color: #f5f5f5;
  .nearby {
    width: 1024px;
    margin: 0 auto;

    .list-nearby {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      li {
        width: 33.33%;
        .box {
          padding: 15px 10px;
          .description {
            width: 100%;
            border: 1px #bebebe solid;

            .image {
              background-color: #cbcbcb;
              height: 250px;
              position: relative;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              span {
                position: absolute;
                top: 15px;
                right: 15px;
                padding: 5px 10px;
                border-radius: 40px;
                background-color: #272727;
                color: #fff;
                font-size: 10px;
              }
            }

            .detail {
              position: relative;
              padding: 20px;
              .btn-booknow {
                position: absolute;
                top: -20px;
                right: 15px;
                padding: 10px 15px;
                border-radius: 40px;
                border: 0;
                background-color: #0058ff;
                color: #fff;
                font-size: 12px;
                font-weight: bold;
              }

              .title {
                font-size: 18px;
                font-weight: bold;
              }

              .like {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }

    .slider {
      display: none;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 0;
    .nearby {
      width: 100%;
      .list-nearby {
        display: none;
      }

      .slider {
        display: block;
      }
    }

    .box {
      padding: 15px 10px;
      .description {
        width: 100%;
        border: 1px #bebebe solid;

        .image {
          background-color: #cbcbcb;
          height: 200px;
          position: relative;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          span {
            position: absolute;
            top: 15px;
            right: 15px;
            padding: 5px 10px;
            border-radius: 40px;
            background-color: #272727;
            color: #fff;
            font-size: 10px;
          }
        }

        .detail {
          position: relative;
          padding: 20px;
          .btn-booknow {
            position: absolute;
            top: -20px;
            right: 15px;
            padding: 10px 15px;
            border-radius: 40px;
            border: 0;
            background-color: #0058ff;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
          }

          .title {
            font-size: 18px;
            font-weight: bold;
          }

          .like {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>