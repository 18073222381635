<template>
  <div class="list-menu">
    <ul>
      <li>
        <a href="#">
          <img :src="icon02" alt="" />
          <br />
          Snapshots
        </a>
      </li>
      <li>
        <a href="#">
          <img :src="icon01" alt="" />
          <br />
          Nearby
        </a>
      </li>
      <li>
        <a href="#">
          <img :src="icon06" alt="" />
          <br />
          Campaigns
        </a>
      </li>
      <li>
        <a href="#">
          <img :src="icon05" alt="" />
          <br />
          Ways to Experience
        </a>
      </li>
      <li>
        <a href="#">
          <img :src="icon04" alt="" />
          <br />
          Stories
        </a>
      </li>
      <li>
        <a href="#">
          <img :src="icon03" alt="" />
          <br />
          Contact
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import icon01 from "@/assets/images/Group26.svg";
import icon02 from "@/assets/images/Group29.svg";
import icon03 from "@/assets/images/Group39.svg";
import icon04 from "@/assets/images/Group43.svg";
import icon05 from "@/assets/images/Group586.svg";
import icon06 from "@/assets/images/Group587.svg";
export default {
  data() {
    return {
      icon01: icon01,
      icon02: icon02,
      icon03: icon03,
      icon04: icon04,
      icon05: icon05,
      icon06: icon06,
    };
  },
};
</script>

<style lang="scss">
.list-menu {
  margin: auto;
  max-width: 1140px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    li {
      text-align: center;
      a {
        color: #000;
        font-size: 15px;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .list-menu {
    display: none;
  }
}
</style>