<template>
  <div class="website">
    <ul class="section">
      <li>
        <img class="logo-rating" :src="ws01" alt="" />
        <div class="detail">
          <p>
            <i>Booking.com</i>
          </p>
          <p>
            <button type="button" class="btn-rate">4.5</button>
          </p>
          <p>
            <img :src="star" alt="" />
          </p>
        </div>
      </li>
      <li>
        <img class="logo-rating" :src="ws02" alt="" />
        <div class="detail">
          <p>
            <i>Tripadvisor</i>
          </p>
          <p>
            <button type="button" class="btn-rate">4.5</button>
          </p>
          <p>
            <img :src="star" alt="" />
          </p>
        </div>
      </li>
      <li>
        <img class="logo-rating" :src="ws03" alt="" />
        <div class="detail">
          <p>
            <i>Expedia</i>
          </p>
          <p>
            <button type="button" class="btn-rate">4.5</button>
          </p>
          <p>
            <img :src="star" alt="" />
          </p>
        </div>
      </li>
      <li>
        <img class="logo-rating" :src="ws04" alt="" />
        <div class="detail">
          <p>
            <i>CTrip</i>
          </p>
          <p>
            <button type="button" class="btn-rate">4.5</button>
          </p>
          <p>
            <img :src="star" alt="" />
          </p>
        </div>
      </li>
      <li>
        <img class="logo-rating" :src="ws05" alt="" />
        <div class="detail">
          <p>
            <i>Agoda</i>
          </p>
          <p>
            <button type="button" class="btn-rate">4.5</button>
          </p>
          <p>
            <img :src="star" alt="" />
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ws01 from "@/assets/images/ws01.svg";
import ws02 from "@/assets/images/ws02.svg";
import ws03 from "@/assets/images/ws03.svg";
import ws04 from "@/assets/images/ws04.svg";
import ws05 from "@/assets/images/ws05.svg";
import star from "@/assets/images/star.svg";
export default {
  data() {
    return {
      ws01: ws01,
      ws02: ws02,
      ws03: ws03,
      ws04: ws04,
      ws05: ws05,
      star: star,
    };
  },
};
</script>

<style lang="scss">
.website {
  padding: 60px;
  background-color: #f4f4f4;
  .section {
    margin: 0 auto;
    width: 800px;
    display: flex;
    justify-content: space-between;
    li {
      text-align: center;
      font-weight: bold;
      p {
        margin: 10px;
      }
      .btn-rate {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        background-color: #0058ff;
        border-radius: 8px;
        border: 0;
        padding: 10px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 20px 30px;
    .section {
      width: 100%;
      flex-wrap: wrap;
      li {
        width: 100%;
        height: 50px;
        padding-left: 15px;
        margin-bottom: 8px;
        background-color: #ffffff;
        border: 1px #bebebe solid;
        display: flex;
        align-items: center;
        img.logo-rating {
          height: 35px;
        }

        .detail {
          width: 100%;
          height: 30px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-auto-rows: 1fr;
          padding: 0 0 0 15px;
          p {
            line-height: 16px;
            padding: 0px;
            margin: 0px;
            &:nth-child(1) {
              order: 1;
              grid-column: 1 / span 2;
              grid-row: 1 / span 1;
              text-align: left;

              i {
                font-size: 9px;
              }
            }
            &:nth-child(2) {
              order: 3;
              grid-column: 1 / span -1;
              grid-row: 1 / span 2;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:nth-child(3) {
              order: 2;
              grid-column: 1 / span 2;
              grid-row: 2 / span 1;
              text-align: left;
            }

            .btn-rate {
              font-size: 15px;
              border-radius: 5px;
              border: 0;
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }
}
</style>