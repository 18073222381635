<template>
  <header class="ew-page-header">
    <div class="logo">
      <a :href="homeUrl">
        <img :src="logo" class="logo-img" /> 
      </a>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    logo: String,
    homeUrl: {
      type: String,
      default: "/",
    },
  },
};
</script>

<style lang="scss" scoped>
.ew-page-header {
  width: 100%;
  height: 57px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .logo {
    position: fixed;
    top: 20px;
    left: 20px;
    

    & > a {
      display: block;
    }

    .logo-img {
      width: auto;
      height: 63px;
    }
  }
}
</style>