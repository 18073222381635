<template>
  <div class="banner">
    <div class="img-banner-desktop">
      <img :src="banner" alt="" />
    </div>
    <div class="img-banner-mobile">
      <img :src="banner2" alt="" />
    </div>
  </div>
</template>

<script>
import banner from "@/assets/images/banner.svg";
import banner2 from "@/assets/images/banner2.svg";
export default {
  data() {
    return {
      banner: banner,
      banner2: banner2
    };
  },
};
</script>

<style lang="scss">
.banner {
  text-align: center;
  width: 100%;
  .img-banner-desktop {
    margin: 0 auto;
    width: 728px;
    height: 90px;
    display: block;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .img-banner-mobile {
    display: none;
  }

  @media only screen and (max-width: 900px) {
    .img-banner-desktop {
      display: none;
    }

    .img-banner-mobile {
      margin: 0 auto;
      width: 320px;
      height: 100px;
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>