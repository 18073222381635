var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-nearby"},[_c('div',{staticClass:"nearby"},[_c('h1',[_vm._v("Nearby Hotels")]),_c('ul',{staticClass:"list-nearby"},_vm._l((_vm.nearbyHotels),function(exp,index){return _c('li',{key:index},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"description"},[_c('a',{attrs:{"href":exp.experienceUrl,"target":"_blank"}},[_c('div',{staticClass:"image",style:({
                  'background-image': exp.mainImageUrl
                    ? ("url(" + (exp.mainImageUrl) + ")")
                    : null,
                })},[_c('span',[_vm._v(_vm._s(exp.distanceText))])])]),_c('div',{staticClass:"detail"},[_c('button',{staticClass:"btn-booknow"},[_vm._v("BookNow")]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(exp.title))]),_vm._m(0,true)])])])])}),0),_c('div',{staticClass:"slider",staticStyle:{"width":"100%"}},[_c('splide',{staticStyle:{"width":"100%"},attrs:{"options":_vm.options}},_vm._l((_vm.nearbyHotels),function(exp,index){return _c('splide-slide',{key:index},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"description"},[_c('a',{attrs:{"href":exp.experienceUrl,"target":"_blank"}},[_c('div',{staticClass:"image",style:({
                    'background-image': exp.mainImageUrl
                      ? ("url(" + (exp.mainImageUrl) + ")")
                      : null,
                  })},[_c('span',[_vm._v(_vm._s(exp.distanceText))])])]),_c('div',{staticClass:"detail"},[_c('button',{staticClass:"btn-booknow"},[_vm._v("BookNow")]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(exp.title))]),_c('div',{staticClass:"like"},[_c('span',[_vm._v("210")])])])])])])}),1)],1),_c('div',{staticStyle:{"width":"100%"}},[_c('Banner')],1),_c('h1',[_vm._v("Nearby Experiences")]),_c('ul',{staticClass:"list-nearby"},_vm._l((_vm.nearbyExperiences),function(exp,index){return _c('li',{key:index},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"description"},[_c('a',{attrs:{"href":exp.experienceUrl,"target":"_blank"}},[_c('div',{staticClass:"image",style:({
                  'background-image': exp.mainImageUrl
                    ? ("url(" + (exp.mainImageUrl) + ")")
                    : null,
                })},[_c('span',[_vm._v(_vm._s(exp.distanceText))])])]),_c('div',{staticClass:"detail"},[_c('button',{staticClass:"btn-booknow"},[_vm._v("BookNow")]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(exp.title))]),_vm._m(1,true)])])])])}),0),_c('div',{staticClass:"slider",staticStyle:{"width":"100%"}},[_c('splide',{staticStyle:{"width":"100%"},attrs:{"options":_vm.options}},_vm._l((_vm.nearbyExperiences),function(exp,index){return _c('splide-slide',{key:index},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"description"},[_c('a',{attrs:{"href":exp.experienceUrl,"target":"_blank"}},[_c('div',{staticClass:"image",style:({
                    'background-image': exp.mainImageUrl
                      ? ("url(" + (exp.mainImageUrl) + ")")
                      : null,
                  })},[_c('span',[_vm._v(_vm._s(exp.distanceText))])])]),_c('div',{staticClass:"detail"},[_c('button',{staticClass:"btn-booknow"},[_vm._v("BookNow")]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(exp.title))]),_c('div',{staticClass:"like"},[_c('span',[_vm._v("210")])])])])])])}),1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"like"},[_c('span',[_vm._v("210")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"like"},[_c('span',[_vm._v("210")])])}]

export { render, staticRenderFns }