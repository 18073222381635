export default class Repository {
  constructor() {
    this.url = "https://api.enwoke.com/graphql";
  }

  async getExperienceByName(id, name) {
    const query = require("../graphql/queries/experience.gql").default;
    const body = {
      query,
      variables: {
        id,
        name,
      },
    };
    const response = await window.fetch(this.url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const { data, error } = await response.json();
    if (response.ok) {
      return data.experience;
    } else {
      throw new Error(error?.message ?? "Unknow");
    }
  }

  /**
   * Get all moments by specific social identifiers.
   * @param {String} identifiers Hashtag or account name.
   * @param {Number} limit Number of result items per page.
   * @param {Number} page Page that you want to get results.
   * @returns 
   */
  async getMomentsBySocialIdentifiers(identifiers, limit = 10, page = 1) {
    const query = require("../graphql/queries/momentsBySocialIdentifiers.gql")
      .default;
    const body = {
      query,
      variables: {
        identifiers,
        limit,
        page,
      },
    };
    const response = await window.fetch(this.url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const { data, error } = await response.json();
    if (response.ok) {
      return data.momentsBySocialIdentifiers;
    } else {
      throw new Error(error?.message ?? "Unknow");
    }
  }
}
